<template>
    <b-card>
        <div v-if="prospect.rukanholder.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Titular</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(rukanholder, index) in prospect.rukanholder" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">R.U.N.</h4>
                        <h6 class="font-weight-normal">{{rukanholder.rut_holder}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Nombre</h4>
                        <h6 class="font-weight-normal">{{rukanholder.name}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Sexo</h4>
                        <h6 class="font-weight-normal">{{rukanholder.sex}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado civil</h4>
                        <h6 class="font-weight-normal">{{rukanholder.marital_status}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Country</h4>
                        <h6 class="font-weight-normal">{{rukanholder.country}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha de nacimiento</h4>
                        <h6 class="font-weight-normal">{{rukanholder.date_birth | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha defunción</h4>
                        <h6 class="font-weight-normal">{{rukanholder.date_death | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Discapacidad</h4>
                        <h6 class="font-weight-normal">{{rukanholder.disability === true ? 'Si' : 'No'}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha creación</h4>
                        <h6 class="font-weight-normal">{{rukanholder.created_at | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.rukanpartner.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Cónyuge</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(rukanpartner, index) in prospect.rukanpartner" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">R.U.N.</h4>
                        <h6 class="font-weight-normal">{{rukanpartner.rut_partner}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Nombre</h4>
                        <h6 class="font-weight-normal">{{rukanpartner.name}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Sexo</h4>
                        <h6 class="font-weight-normal">{{rukanpartner.sex}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado civil</h4>
                        <h6 class="font-weight-normal">{{rukanpartner.marital_status}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Country</h4>
                        <h6 class="font-weight-normal">{{rukanpartner.country}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha de nacimiento</h4>
                        <h6 class="font-weight-normal">{{rukanpartner.date_birth | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha defunción</h4>
                        <h6 class="font-weight-normal">{{rukanpartner.date_death | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Discapacidad</h4>
                        <h6 class="font-weight-normal">{{rukanpartner.disability === true ? 'Si' : 'No'}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha creación</h4>
                        <h6 class="font-weight-normal">{{rukanpartner.created_at | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.rukanpartner.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Hijos</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(rukanson, index) in prospect.rukanson" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">R.U.N.</h4>
                        <h6 class="font-weight-normal">{{rukanson.rut_son}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Nombre</h4>
                        <h6 class="font-weight-normal">{{rukanson.name}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Sexo</h4>
                        <h6 class="font-weight-normal">{{rukanson.sex}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado civil</h4>
                        <h6 class="font-weight-normal">{{rukanson.marital_status}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Country</h4>
                        <h6 class="font-weight-normal">{{rukanson.country}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha de nacimiento</h4>
                        <h6 class="font-weight-normal">{{rukanson.date_birth | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha defunción</h4>
                        <h6 class="font-weight-normal">{{rukanson.date_death | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Discapacidad</h4>
                        <h6 class="font-weight-normal">{{rukanson.disability === true ? 'Si' : 'No'}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha creación</h4>
                        <h6 class="font-weight-normal">{{rukanson.created_at | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.holdersocialregistry.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Titular registro social</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(holdersocialregistry, index) in prospect.holdersocialregistry" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Tramo CSE</h4>
                        <h6 class="font-weight-normal">{{holdersocialregistry.tramo_cse}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Folio</h4>
                        <h6 class="font-weight-normal">{{holdersocialregistry.folio}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha de encuesta</h4>
                        <h6 class="font-weight-normal">{{holdersocialregistry.date_poll | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Región</h4>
                        <h6 class="font-weight-normal">{{holdersocialregistry.region}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Ciudad</h4>
                        <h6 class="font-weight-normal">{{holdersocialregistry.city}}</h6>
                    </b-col>
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.membersocialregistry.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Registro social de miembros</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(membersocialregistry, index) in prospect.membersocialregistry" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">R.U.N.</h4>
                        <h6 class="font-weight-normal">{{membersocialregistry.rut_member}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Nombre miembro</h4>
                        <h6 class="font-weight-normal">{{membersocialregistry.name_member}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Relación</h4>
                        <h6 class="font-weight-normal">{{membersocialregistry.relatioship}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Fecha de nacimiento</h4>
                        <h6 class="font-weight-normal">{{membersocialregistry.member_date_birth | moment("DD-MM-YYYY")}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Está postulando</h4>
                        <h6 class="font-weight-normal">{{membersocialregistry.is_postulating}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Proyectos de reservas</h4>
                        <h6 class="font-weight-normal">{{membersocialregistry.booking_projects}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Afectado</h4>
                        <h6 class="font-weight-normal">{{membersocialregistry.affected}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Servicio miliar</h4>
                        <h6 class="font-weight-normal">{{membersocialregistry.military_service}}</h6>
                    </b-col>
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.residencedata.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Datos de residencia</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(residencedata, index) in prospect.residencedata" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Dormitorios</h4>
                        <h6 class="font-weight-normal">{{residencedata.bedrooms}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Superpoblación</h4>
                        <h6 class="font-weight-normal">{{residencedata.overcrowding}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Tipo residencia</h4>
                        <h6 class="font-weight-normal">{{residencedata.type_residence}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Distribución de agua</h4>
                        <h6 class="font-weight-normal">{{residencedata.water_distribution}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Sistema de agua</h4>
                        <h6 class="font-weight-normal">{{residencedata.water_system}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Murallas</h4>
                        <h6 class="font-weight-normal">{{residencedata.walls}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Pisos</h4>
                        <h6 class="font-weight-normal">{{residencedata.floors}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Techos</h4>
                        <h6 class="font-weight-normal">{{residencedata.ceilings}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado de conservación</h4>
                        <h6 class="font-weight-normal">{{residencedata.conservation_status}}</h6>
                    </b-col>
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.ruralhousingprogram.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Programa habitacional rural</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(ruralhousingprogram, index) in prospect.ruralhousingprogram" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Cálculo déficit habitabilidad</h4>
                        <h6 class="font-weight-normal">{{ruralhousingprogram.deficit_hability}}</h6>
                    </b-col>
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.holdersubsidy.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Subsidio Titular</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(holdersubsidy, index) in prospect.holdersubsidy" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">R.U.N.</h4>
                        <h6 class="font-weight-normal">{{holdersubsidy.rut}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Calidad</h4>
                        <h6 class="font-weight-normal">{{holdersubsidy.quality}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Programa</h4>
                        <h6 class="font-weight-normal">{{holdersubsidy.program}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Oferta</h4>
                        <h6 class="font-weight-normal">{{holdersubsidy.offer}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Año subsidio</h4>
                        <h6 class="font-weight-normal">{{holdersubsidy.year_subsidy}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Región</h4>
                        <h6 class="font-weight-normal">{{holdersubsidy.region}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Provincia</h4>
                        <h6 class="font-weight-normal">{{holdersubsidy.province}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Ciudad</h4>
                        <h6 class="font-weight-normal">{{holdersubsidy.city}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado subsidio</h4>
                        <h6 class="font-weight-normal">{{holdersubsidy.state_subsidy}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado pagado</h4>
                        <h6 class="font-weight-normal">{{holdersubsidy.state_paid}}</h6>
                    </b-col> 
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.membersubsidy.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Subsidio integrantes</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(membersubsidy, index) in prospect.membersubsidy" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">R.U.N.</h4>
                        <h6 class="font-weight-normal">{{membersubsidy.rut}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Calidad</h4>
                        <h6 class="font-weight-normal">{{membersubsidy.quality}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Programa</h4>
                        <h6 class="font-weight-normal">{{membersubsidy.program}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Oferta</h4>
                        <h6 class="font-weight-normal">{{membersubsidy.offer}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Año subsidio</h4>
                        <h6 class="font-weight-normal">{{membersubsidy.year_subsidy}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Región</h4>
                        <h6 class="font-weight-normal">{{membersubsidy.region}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Provincia</h4>
                        <h6 class="font-weight-normal">{{membersubsidy.province}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Ciudad</h4>
                        <h6 class="font-weight-normal">{{membersubsidy.city}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado subsidio</h4>
                        <h6 class="font-weight-normal">{{membersubsidy.state_subsidy}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado pagado</h4>
                        <h6 class="font-weight-normal">{{membersubsidy.state_paid}}</h6>
                    </b-col>
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.partnersubsidy.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Subsidio Cónyuge</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(partnersubsidy, index) in prospect.partnersubsidy" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">R.U.N.</h4>
                        <h6 class="font-weight-normal">{{partnersubsidy.rut}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Calidad</h4>
                        <h6 class="font-weight-normal">{{partnersubsidy.quality}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Programa</h4>
                        <h6 class="font-weight-normal">{{partnersubsidy.program}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Oferta</h4>
                        <h6 class="font-weight-normal">{{partnersubsidy.offer}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Año subsidio</h4>
                        <h6 class="font-weight-normal">{{partnersubsidy.year_subsidy}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Región</h4>
                        <h6 class="font-weight-normal">{{partnersubsidy.region}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Provincia</h4>
                        <h6 class="font-weight-normal">{{partnersubsidy.province}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Ciudad</h4>
                        <h6 class="font-weight-normal">{{partnersubsidy.city}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado subsidio</h4>
                        <h6 class="font-weight-normal">{{partnersubsidy.state_subsidy}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Estado pagado</h4>
                        <h6 class="font-weight-normal">{{partnersubsidy.state_paid}}</h6>
                    </b-col> 
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.holderproperty.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Propiedades titular</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(holderproperty, index) in prospect.holderproperty" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">R.U.N.</h4>
                        <h6 class="font-weight-normal">{{holderproperty.rut}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Región</h4>
                        <h6 class="font-weight-normal">{{holderproperty.region}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Provincia</h4>
                        <h6 class="font-weight-normal">{{holderproperty.province}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Comuna</h4>
                        <h6 class="font-weight-normal">{{holderproperty.commune}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Destino</h4>
                        <h6 class="font-weight-normal">{{holderproperty.destination}}</h6>
                    </b-col>
                </b-row>
                <hr/>
            </div>
        </div>
        <div v-if="prospect.partnerproperty.length > 0">
            <div class="mx-1 mt-4 mb-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="BookmarkIcon"
                            size="20"
                            class="mr-50"/>Propiedades cónyuge</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-for="(partnerproperty, index) in prospect.partnerproperty" :key="index">
                <b-row class="mx-2">
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">R.U.N.</h4>
                        <h6 class="font-weight-normal">{{partnerproperty.rut}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Región</h4>
                        <h6 class="font-weight-normal">{{partnerproperty.region}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Provincia</h4>
                        <h6 class="font-weight-normal">{{partnerproperty.province}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Comuna</h4>
                        <h6 class="font-weight-normal">{{partnerproperty.commune}}</h6>
                    </b-col>
                    <b-col cols="6" md="4" class="my-1">
                        <h4 class="font-weight-bolder">Destino</h4>
                        <h6 class="font-weight-normal">{{partnerproperty.destination}}</h6>
                    </b-col>
                </b-row>
                <hr/>
            </div>
        </div>
    </b-card>
</template>

<script>

    export default {
        name: 'BusinessRukan',
        props: ['prospect'],
        data () {
            return {
            }
        },
        beforeMount(){
            console.log(this.prospect)
        }
    }
</script>