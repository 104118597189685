<template>
    <div>
        <b-card>
            <div class="borderDiv my-2">
                <div class="mx-3 mt-2">
                    <b-row>
                        <b-col>
                            <div class="align-center flex">
                                <h4 class="font-weight-bolder"><feather-icon
                                icon="BookmarkIcon"
                                size="20"
                                class="mr-50"/>Cotizaciones</h4>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="mx-12 px-12 my-2">
                    <b-table
                        hover
                        striped
                        show-empty
                        responsive="lg"
                        ref="refQuotationsListTable"
                        class="position-relative mb-1 mx-2"
                        :items="prospect"
                        :fields="tableColumns"
                        primary-key="id"
                        empty-text="Datos no Disponibles">
                        <template #cell(project)="data" >
                            <span v-if="data.item.project != null">{{ data.item.project.name }}</span>
                        </template>
                        <template #cell(identifier)="data">
                            <span v-if="data.item.project != null">{{ data.item.project.property ? data.item.project.property[0].identifier : '-' }}</span>
                        </template>
                        <template #cell(price)="data">
                            <span v-if="data.item.project != null">{{ data.item.project.property ? data.item.project.property[0].price : '-' }}</span>
                        </template>
                        <template #cell(date)="data">
                            <span v-if="data.item.project != null">{{ data.item.created_at | moment("DD-MM-YYYY") }}</span>
                        </template>
                    </b-table>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: 'BusinessDetailsReservationRequest',
        props: ['prospect'],
        data () {
            return {
                tableColumns: [
                    {key: 'project', sortable: false, label: 'Proyecto'},
                    {key: 'identifier', sortable: false, label: 'Identificador'},
                    {key: 'price', sortable: false, label: 'Precio'},
                    {key: 'date', sortable: false, label: 'Fecha creación'},
                ],
            }
        }
    }
</script>

<style scoped>
    .flex {
        display: flex;
        align-items: center;
    }
</style>