<template>
    <div>
        <b-row>
            <b-col vs-w="12">
                <b-card>
                    <b-row class="mb-2">
                        <b-col cols="2">
                            <feather-icon
                            icon="UserIcon"
                            size="50"
                            :color="_colorCriticality(prospect.criticality)"
                            class="mr-50"/>
                        </b-col>
                        <b-col cols="10">
                            <b-row>
                                <b-col cols="6">
                                    <h4 class="font-weight-bolder">{{prospect.customer.name}} {{prospect.customer.last_name}}</h4>
                                </b-col>
                                <b-col cols="6">
                                    <h4 class="font-weight-bolder">{{prospect.customer.email}}</h4>
                                </b-col>
                                <b-col cols="6">
                                    <h4 class="font-weight-bolder">{{prospect.customer.rut}}-{{prospect.customer.rut_dv}}</h4>
                                </b-col>
                                <b-col cols="6">
                                    <h4 class="font-weight-bolder">{{prospect.customer.phone}}</h4>
                                </b-col>
                                <b-col cols="6">
                                    <h4 class="font-weight-bolder">{{prospect.customer.subsidio}}</h4>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <div class="borderDiv my-2">
                        <div class="mx-12 my-2">
                            <b-row class="mx-2"> 
                                <b-col cols="12" class="my-2">
                                    <b-form-group label="Estado" label-for="condition">
                                        <v-select
                                            v-model="selectedPipeLineState"
                                            label="name"
                                            placeholder="Selecciona un estado"
                                            :options="pipeLineStages"
                                            :reduce="pipeLineStages => pipeLineStages.id">
                                            <span slot="no-options">
                                            No se encontraron opciones
                                            </span>
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" class="my-2">
                                    <b-form-group label="Detalles" label-for="project_description">
                                        <b-form-textarea
                                        id="project_description"
                                        v-model="descriptionState"
                                        placeholder="Escribe la información aquí..."
                                        rows="6"
                                        max-rows="12"
                                        ></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mx-2" align="end">
                                <b-col cols="12">
                                    <b-overlay
                                        :show="overlayForm"
                                        opacity="0.5"
                                        spinner-small
                                        spinner-variant="primary"
                                        class="d-inline-block"
                                        @hidden="_onHidden()" >
                                        <b-button
                                        ref="button"
                                        variant="primary"
                                        :disabled="overlayForm"
                                        @click.prevent="_saveState">
                                        <feather-icon
                                            icon="DownloadIcon"
                                            class="mr-50"/>
                                        <span class="align-middle">
                                            Guardar
                                        </span>
                                        </b-button>
                                    </b-overlay>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <!-- <div class="borderDiv my-2">
                        <div class="mx-3 mt-2">
                            <b-row>
                                <b-col>
                                    <div class="align-center flex">
                                        <h4 class="font-weight-bolder"><feather-icon
                                        icon="UserIcon"
                                        size="20"
                                        class="mr-50"/>Cotización</h4>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="mx-12 px-12 my-2">
                            <b-row class="mx-2">
                                <b-col cols="4" class="my-1">
                                    <h4 class="font-weight-bolder">Nombre</h4>
                                    <h6 class="font-weight-normal">{{prospect.quotation.customer.name}}</h6>
                                </b-col>
                                <b-col cols="4" class="my-1">
                                    <h4 class="font-weight-bolder">Apellido</h4>
                                    <h6 class="font-weight-normal">{{prospect.quotation.customer.last_name}}</h6>
                                </b-col>
                                <b-col cols="4" class="my-1">
                                    <h4 class="font-weight-bolder">R.U.N.</h4>
                                    <h6 class="font-weight-normal">{{prospect.quotation.customer.rut}}-{{prospect.quotation.customer.rut_dv}}</h6>
                                </b-col>
                                <b-col cols="4" class="my-1">
                                    <h4 class="font-weight-bolder">Correo</h4>
                                    <h6 class="font-weight-normal">{{prospect.quotation.customer.email}}</h6>
                                </b-col>
                                <b-col cols="4" class="my-1">
                                    <h4 class="font-weight-bolder">Teléfono</h4>
                                    <h6 class="font-weight-normal">{{prospect.quotation.customer.phone}}</h6>
                                </b-col>
                                <b-col cols="4" class="my-1">
                                    <h4 class="font-weight-bolder">Estado</h4>
                                    <h6 class="font-weight-normal">{{prospect.quotation.customer.status}}</h6>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="mx-5">
                            <hr/>
                        </div>
                        <div class="mx-12 px-12 my-2">
                            <b-row class="mx-2">
                                <b-col cols="4" class="my-1">
                                    <h4 class="font-weight-bolder">Renta Mínima</h4>
                                    <h6 class="font-weight-normal">{{prospect.quotation.minimum_rent}}</h6>
                                </b-col>
                                <b-col cols="4" class="my-1">
                                    <h4 class="font-weight-bolder">Renta Máxima</h4>
                                    <h6 class="font-weight-normal">{{prospect.quotation.maximum_rent}}</h6>
                                </b-col>
                            </b-row>
                        </div>
                    </div> -->
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import ToastificationContent from "@core/components/toastification/ToastificationContent"

    export default {
        name: 'BusinessDetailsGeneral',
        props: ['prospect'],
        data () {
            return {
                overlayForm: false,
                idState: 0,
                selectedPipeLineState:{id:1, name:"En proceso"},
                descriptionState: "",
                pipeLineStages:[
                    {id:1, name:"En proceso"},
                    {id:2, name:"Consulta Manual"},
                    {id:3, name:"Hábil"},
                    {id:4, name:"No Hábil"},
                    {id:5, name:"Cerrado"},
                ]
            }
        },
        methods:{
            ...mapActions('business',['changeState']),
            async _saveState(){
                if(this.descriptionState != ""){ 
                    let newState = {
                        id: this.idState, 
                        description: this.descriptionState,
                    };
                    if(this.selectedPipeLineState.id){
                        newState.pipeline_stage_id = this.selectedPipeLineState.id;
                    }else{
                        newState.pipeline_stage_id = this.selectedPipeLineState;
                    }

                    this.overlayForm = true;
                    this.changeState(newState)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'El estado ha sido actualizado con éxito',
                                icon: 'ThumbsUpIcon',
                                variant: 'success',
                            },
                        })
                        this.$router.push({ name: 'business'});
                    }).catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: 'Error al actualizar el estado',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            },
                        })
                    }).finally(() => {
                        this.overlayForm = false
                    })
                }else{
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: 'Debes agregar una descripción',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        },
                    })
                }
            },
            _colorCriticality(criticality){
                switch (criticality) {
                    case 'high':
                        return 'red'
                    case 'medium':
                        return 'orange'
                    case 'low':
                        return 'green'
                    case 'very_low':
                        return 'blue'
                }
            },
            _onHidden() {
                this.$refs.button.focus()
            },
        },
        beforeMount() {
            this.idState = this.prospect.id 
            this.selectedPipeLineState.id = this.prospect.pipelinestage.id
            this.selectedPipeLineState.name = this.prospect.pipelinestage.name
            this.descriptionState = this.prospect.description
        },
    }
</script>