<template>
    <div>
        <b-card>
            <div class="borderDiv my-2">
                <div class="mx-3 mt-2">
                    <b-row>
                        <b-col>
                            <div class="align-center flex">
                                <h4 class="font-weight-bolder"><feather-icon
                                icon="ClipboardIcon"
                                size="20"
                                class="mr-50"/>Solicitud de promesa</h4>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="mx-12 px-12 my-2">
                    <b-table
                        hover
                        striped
                        show-empty
                        responsive="lg"
                        ref="refQuotationsListTable"
                        class="position-relative mb-1 mx-2"
                        :items="prospect"
                        :fields="tableColumns"
                        primary-key="id"
                        empty-text="Datos no Disponibles">
                        <template #cell(project)="data">
                            {{ data.item.project.name }}
                        </template>
                        <template #cell(status)="data">
                            <b-badge pill :variant="_getStatusColor(data.item.data.status)">
                                {{ _getStatus(data.item.data.status) }}
                            </b-badge>
                        </template>
                        <template #cell(date)="data">
                        {{ data.item.data.created_at | moment("DD-MM-YYYY") }}
                        </template>
                    </b-table>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: 'BusinessDetailsReservationRequest',
        props: ['prospect'],
        data () {
            return {
                tableColumns: [
                    {key: 'project', sortable: false, label: 'Proyecto'},
                    {key: 'status', sortable: false, label: 'Estado'},
                    {key: 'date', sortable: false, label: 'Fecha creación'},
                ],
            }
        },
        methods: {
            _getStatusColor(status){
            switch (status) {
                case 'done':
                    return 'light-success'
                case 'canceled':
                    return 'light-danger'
                case 'pending':
                    return 'light-warning'
            }
            },
            _getStatus(status){
                switch (status) {
                    case 'done':
                        return 'Aprobado'
                    case 'canceled':
                        return 'Cancelado'
                    case 'pending':
                        return 'Pendiente'
                }
            }
        }
    }
</script>

<style scoped>
    .flex {
        display: flex;
        align-items: center;
    }
</style>