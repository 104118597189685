<template>
    <div id="business-details-list">
        <b-tabs pills vertical
            content-class="col-12 col-md-9 mt-1 mt-md-0"
            nav-class="nav-left"
            nav-wrapper-class="col-md-3 col-12">
            <b-tab active>
                <template #title>
                <feather-icon
                    icon="UserIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">General</span>
                </template>
                <business-general :prospect="prospect"></business-general>
            </b-tab>
            <b-tab v-if="this.prospect.rukan.status === true">
                <template #title>
                <feather-icon
                    icon="BookIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Rukan</span>
                </template>
                <business-rukan :prospect="this.prospect.rukan"></business-rukan>
            </b-tab>
            <b-tab v-if="this.quotation.length > 0">
                <template #title>
                <feather-icon
                    icon="BookmarkIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Cotizaciones</span>
                </template>
                <business-quotation :prospect="this.quotation"></business-quotation>
            </b-tab>
            <b-tab v-if="this.preApproval.length > 0">
                <template #title>
                <feather-icon
                    icon="CheckCircleIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Pre aprobados</span>
                </template>
                <business-pre-approval :prospect="this.preApproval"></business-pre-approval>
            </b-tab>
            <b-tab v-if="this.bookingRequest.length > 0">
                <template #title>
                <feather-icon
                    icon="ClipboardIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Solicitud de reserva</span>
                </template>
                <business-booking-request :prospect="this.bookingRequest"></business-booking-request>
            </b-tab>
            <b-tab v-if="this.bookingOrder.length > 0">
                <template #title>
                <feather-icon
                    icon="DollarSignIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Pago de reserva</span>
                </template>
                <business-booking-order :prospect="this.bookingOrder"></business-booking-order>
            </b-tab>
            <b-tab v-if="this.promiseRequest.length > 0">
                <template #title>
                <feather-icon
                    icon="ClipboardIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Solicitud de promesa</span>
                </template>
                <business-promise-request :prospect="this.promiseRequest"></business-promise-request>
            </b-tab>
            <b-tab v-if="this.promiseOrder.length > 0">
                <template #title>
                <feather-icon
                    icon="DollarSignIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Pago de promesa</span>
                </template>
                <business-promise-order :prospect="this.promiseOrder"></business-promise-order>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import BusinessGeneral from './details/BusinessGeneral'
import BusinessRukan from './details/BusinessRukan'
import BusinessQuotation from './details/BusinessQuotation'
import BusinessPreApproval from './details/BusinessPreApproval'
import BusinessBookingRequest from './details/BusinessBookingRequest'
import BusinessBookingOrder from './details/BusinessBookingOrder'
import BusinessPromiseRequest from './details/BusinessPromiseRequest'
import BusinessPromiseOrder from './details/BusinessPromiseOrder'

export default{
    name: 'Business',
    components: {BusinessGeneral, BusinessRukan, BusinessQuotation, BusinessPreApproval, BusinessBookingRequest, BusinessBookingOrder, BusinessPromiseRequest, BusinessPromiseOrder},
    props: ['prospect'],
    data() {
        return {
            rukan: '',
            quotation: [],
            preApproval: [],
            bookingRequest: [],
            bookingOrder: [],
            promiseRequest: [],
            promiseOrder: [],
            project: ''
        }
    },
    created(){
        this.checkData()
    },
    methods:{
        checkData(){
            this.prospect.prospectquotation.forEach(element => {
                this.quotation.push(element.quotation)
            })
            this.prospect.prospectapproval.forEach(element => {
                this.preApproval.push(element.preapproval)
            })
            this.prospect.prospectquotation.forEach(element => {
                if(element.quotation.bookingrequest.length > 0){
                    this.bookingRequest.push({data: element.quotation.bookingrequest[0], project: element.quotation.project})
                    this.bookingOrder.push({data: element.quotation.bookingrequest[0].bookingorder[0], project: element.quotation.project})
                }
            })
            this.prospect.prospectquotation.forEach(element => {
                if(element.quotation.promiserequest.length > 0){
                    this.promiseRequest.push({data: element.quotation.promiserequest[0].promiseorder[0], project: element.quotation.project})
                    this.promiseOrder.push({data: element.quotation.promiserequest[0], project: element.quotation.project})
                }
            })
        }
    }
}
</script>