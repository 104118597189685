<template>
  <div id="business-details-list">
    <business-details-list :prospect="prospect"></business-details-list>
  </div>
</template>

<script>
  import BusinessDetailsList from './components/BusinessDetailsList'

  export default{
    name: 'BusinessDetails',
    components: {BusinessDetailsList},
    props: ['prospect'],
    data() {
      return {
      }
    },
  }
</script>