<template>
    <b-card>
        <div class="borderDiv my-2">
            <div class="mx-3 mt-2">
                <b-row>
                    <b-col>
                        <div class="align-center flex">
                            <h4 class="font-weight-bolder"><feather-icon
                            icon="ClipboardIcon"
                            size="20"
                            class="mr-50"/>Pago de promesa</h4>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="mx-12 px-12 my-2">
                <b-table
                    hover
                    striped
                    show-empty
                    responsive="lg"
                    ref="refQuotationsListTable"
                    class="position-relative mb-1 mx-2"
                    :items="prospect"
                    :fields="tableColumns"
                    primary-key="id"
                    empty-text="Datos no Disponibles">
                    <template #cell(project)="data">
                        {{ data.item.project.name }}
                    </template>
                    <template #cell(status)="data">
                        <b-badge pill :variant="_getStatusColor(data.item.data.status)">
                            {{ _getStatus(data.item.data.status) }}
                        </b-badge>
                    </template>
                    <template #cell(date)="data">
                        {{ data.item.data.datetime_transaction | moment("DD-MM-YYYY") }}
                    </template>
                </b-table>
            </div>
        </div>
    </b-card>
</template>

<script>
    export default {
        name: 'BusinessBookingOrder',
        props: ['prospect'],
        data () {
            return {
                tableColumns: [
                    {key: 'project', sortable: false, label: 'Proyecto'},
                    {key: 'status', sortable: false, label: 'Estado'},
                    {key: 'data.amount_paid', sortable: false, label: 'Monto pagado'},
                    {key: 'data.uf_value', sortable: false, label: 'Valor UF'},
                    {key: 'data.order_number', sortable: false, label: 'Número de orden'},
                    {key: 'date', sortable: false, label: 'Fecha de transacción'},
                ],
            }
        },
        methods:{
            _getStatusColor(status){
                switch (status) {
                    case 'paid':
                        return 'light-success'
                    case 'canceled':
                        return 'light-danger'
                    case 'pending':
                        return 'light-warning'
                }
            },
            _getStatus(status){
                switch (status) {
                    case 'paid':
                        return 'Pagado'
                    case 'canceled':
                        return 'Cancelado'
                    case 'pending':
                        return 'Pendiente'
                }
            }
        }
    }
</script>

<style scoped>
    .flex {
        display: flex;
        align-items: center;
    }
</style>